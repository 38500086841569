import ErrorToast from 'components/ui/toast/ErrorToast'
import Toast from 'components/ui/toast/Toast'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import Api from 'state/apis/api'

enum SocialPlatform {
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  Twitter = 'Twitter'
}

interface Item {
  itemText: string
  itemUrl: string
  itemColor: string
}

interface Section {
  sectionHeading: string
  sectionHeadingColor: string
  items: Item[]
}

interface SocialItem {
  type: SocialPlatform
  href: string
}

interface Props {
  backgroundColor: string
  backgroundImage: any
  logo: any
  footerText: string
  ownerText: string
  sections: Section[]
  newsLetterButtonBackgroundColor: string
  newsLetterButtonTextColor: string
  newsLetterButtonText: string
  socials: SocialItem[]
  socialIconColor: string
  overallTextColor: string
  borderColor: string
  isLastName: boolean
  isFirstName: boolean
  newsletterHeading: string
  newsletterDescription: string
  newsletterDescriptionColor: string
  newsletterHeadingColor: string
  formFieldbg: string
  formFieldTextColor: string
}

export const Footer = ({
  backgroundColor,
  backgroundImage = '',
  logo,
  footerText,
  ownerText,
  sections,
  newsLetterButtonBackgroundColor,
  newsLetterButtonTextColor,
  newsLetterButtonText,
  socials,
  socialIconColor,
  overallTextColor,
  borderColor,
  isFirstName,
  isLastName,
  newsletterHeading,
  newsletterDescription,
  newsletterDescriptionColor,
  newsletterHeadingColor,
  formFieldbg,
  formFieldTextColor
}: Props) => {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: ''
  })
  const [errorToast, setErrorToast] = useState(false)
  const [showToast, setShowToast] = useState(false)

  const handleSetErrorToast = (show: boolean) => {
    setErrorToast(show)
  }

  const handleSetShowToast = (show: boolean) => {
    setShowToast(show)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const { email, firstName: name, lastName: surname } = formData
    Api.post('wwwREST', '/v1/public/email/signup', {
      body: {
        email,
        name,
        surname
      }
    })
      .then(() => {
        setFormData({
          email: '',
          firstName: '',
          lastName: ''
        })
        setShowToast(true)
      })
      .catch(() => {
        setErrorToast(true)
      })
  }
  console.log('== footer basic info', sections, socials)
  const mapColOne = sections[0]
  const mapColTwo = sections[1]
  const mapColThree = sections[2]
  const mapColFour = sections[3]

  const getSocialIcon = (item: SocialItem) => {
    if (item.type === SocialPlatform.Facebook) {
      return (
        <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6" style={{ color: socialIconColor }}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      )
    }

    if (item.type === SocialPlatform.Instagram) {
      return (
        <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6" style={{ color: socialIconColor }}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      )
    }

    if (item.type === SocialPlatform.Twitter) {
      return (
        <svg fill="currentColor" viewBox="0 0 24 24" className="h-6 w-6" style={{ color: socialIconColor }}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      )
    }
  }

  const FirstNameComp = () => {
    if (!isFirstName) return null
    return (
      <>
        <label htmlFor="first-name" className="sr-only">
          First Name
        </label>
        <input
          type="text"
          name="first-name"
          id="first-name"
          required
          className="w-full min-w-0 appearance-none rounded-full bg-white py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none sm:max-w-xs"
          style={{ backgroundColor: formFieldbg, color: formFieldTextColor }}
          placeholder="First Name"
          value={formData.firstName}
          onChange={e => {
            setFormData({ ...formData, firstName: e.target.value })
          }}
        />
      </>
    )
  }

  const LastNameComp = () => {
    if (!isLastName) return null
    return (
      <>
        <label htmlFor="last-name" className="sr-only">
          Last Name
        </label>
        <input
          type="text"
          name="last-name"
          id="last-name"
          required
          className="mt-6 w-full min-w-0 appearance-none rounded-full bg-white py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none sm:mt-0 sm:max-w-xs"
          style={{ backgroundColor: formFieldbg, color: formFieldTextColor }}
          placeholder="Last Name"
          value={formData.lastName}
          onChange={e => {
            setFormData({ ...formData, lastName: e.target.value })
          }}
        />
      </>
    )
  }

  return (
    <footer
      className="bg-white"
      aria-labelledby="footer-heading"
      style={{
        backgroundColor,
        backgroundImage: backgroundImage ? `url(${backgroundImage ?? ''})` : 'none',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        {/* xl:grid xl:grid-cols-3 xl:gap-8 */}
        <div className="sm:grid sm:grid-cols-2 sm:gap-8">
          <div className="mx-auto w-full max-w-md space-y-8 xl:col-span-1">
            <img
              className="h-full max-h-20"
              src={logo || 'https://tailwindui.com/img/logos/workflow-mark-gray-300.svg'}
              alt="Different Dog"
            />

            {/* { newsletter row } */}
            <div
              className="border-t border-gray-200 pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0"
              style={{ borderColor: borderColor }}
            >
              <div>
                <h3 className="text-base tracking-wider text-gray-400" style={{ color: newsletterHeadingColor }}>
                  {newsletterHeading}
                </h3>
                <p className="mt-2 text-sm text-gray-500" style={{ color: newsletterDescriptionColor }}>
                  {newsletterDescription}
                </p>
              </div>
            </div>

            {/* sm:flex sm:max-w-md */}
            <form className="mt-4 lg:mt-0" onSubmit={handleSubmit}>
              <div className="sm:flex sm:gap-8">
                {FirstNameComp()}
                {LastNameComp()}
              </div>
              <div className="mt-6 w-full">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  name="email-address"
                  id="email-address"
                  autoComplete="email"
                  required
                  className="w-full min-w-0 appearance-none rounded-full bg-white py-2 px-4 text-base text-gray-900 placeholder-gray-500 focus:outline-none"
                  placeholder="Enter your email"
                  value={formData.email}
                  style={{ backgroundColor: formFieldbg, color: formFieldTextColor }}
                  onChange={e => {
                    setFormData({ ...formData, email: e.target.value })
                  }}
                />
              </div>
              {/* sm:ml-3 */}
              <div className="mt-6 w-3/4 rounded-full sm:flex-shrink-0">
                <button
                  type="submit"
                  // rounded-full
                  className="flex items-center justify-center rounded-full border border-transparent bg-indigo-600 py-2 px-4 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  style={{ backgroundColor: newsLetterButtonBackgroundColor, color: newsLetterButtonTextColor }}
                >
                  {newsLetterButtonText}
                </button>
              </div>
            </form>

            <div className="flex space-x-6">
              {socials?.map((item, i) => (
                <Link key={i} to={item.href} className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">{item.type}</span>
                  {getSocialIcon(item)}
                </Link>
              ))}
            </div>

            <div
              className="mt-12 mb-8 hidden border-t border-gray-200 pt-8 sm:block"
              style={{ borderColor: borderColor }}
            >
              <p className="text-base text-gray-400 xl:text-center" style={{ color: overallTextColor }}>
                {ownerText}
              </p>
            </div>
          </div>
          {/* xl:col-span-2 */}
          <div className="mx-auto mt-12 grid w-full max-w-md grid-cols-1 gap-8 justify-self-end xl:mt-0">
            <div className="grid grid-cols-2 gap-8">
              <div>
                <h3
                  className="text-sm font-semibold tracking-wider text-gray-400"
                  style={{ color: mapColOne?.sectionHeadingColor }}
                >
                  {mapColOne?.sectionHeading}
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {mapColOne?.items?.map((item, i) => (
                    <li key={i}>
                      <Link
                        to={item.itemUrl}
                        className="text-base text-gray-500 hover:text-gray-900"
                        style={{ color: item.itemColor }}
                      >
                        {item.itemText}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              {/* className="mt-12 md:mt-0" */}
              <div>
                <h3
                  className="text-sm font-semibold tracking-wider text-gray-400"
                  style={{ color: mapColTwo?.sectionHeadingColor }}
                >
                  {mapColTwo?.sectionHeading}
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {mapColTwo?.items?.map((item, i) => (
                    <li key={i}>
                      <Link
                        to={item.itemUrl}
                        className="text-base text-gray-500 hover:text-gray-900"
                        style={{ color: item.itemColor }}
                      >
                        {item.itemText}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-8">
              <div>
                <h3
                  className="text-sm font-semibold tracking-wider text-gray-400"
                  style={{ color: mapColThree?.sectionHeadingColor }}
                >
                  {mapColThree?.sectionHeading}
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {mapColThree?.items?.map((item, i) => (
                    <li key={i}>
                      <Link
                        to={item.itemUrl}
                        className="text-base text-gray-500 hover:text-gray-900"
                        style={{ color: item.itemColor }}
                      >
                        {item.itemText}
                      </Link>
                    </li>
                  ))}
                </ul>
                <p className="mt-4 text-base text-gray-500" style={{ color: overallTextColor }}>
                  {footerText}
                </p>
              </div>
              {/* className="mt-12 md:mt-0" */}
              <div>
                <h3
                  className="text-sm font-semibold tracking-wider text-gray-400"
                  style={{ color: mapColFour?.sectionHeadingColor }}
                >
                  {mapColFour?.sectionHeading}
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {mapColFour?.items?.map((item, i) => (
                    <li key={i}>
                      <Link
                        to={item.itemUrl}
                        className="text-base text-gray-500 hover:text-gray-900"
                        style={{ color: item.itemColor }}
                      >
                        {item.itemText}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className="mt-12 mb-4 block border-t border-gray-200 pt-8 text-center sm:hidden"
          style={{ borderColor: borderColor }}
        >
          <p className="text-center text-base text-gray-400" style={{ color: overallTextColor }}>
            {ownerText}
          </p>
          {/* <div className="mx-auto mt-4 w-full max-w-[4rem]">
            <img className="mx-auto" src={Visa} alt="Visa payment" />
          </div> */}
        </div>

        <ErrorToast
          showToast={errorToast}
          msg={'Please enter valid email address'}
          handleSetShowToast={handleSetErrorToast}
          handleOnClick={() => {}}
        />
        <Toast showToast={showToast} msg={'Thank you for subscribing'} handleSetShowToast={handleSetShowToast} />
      </div>
    </footer>
  )
}

export default Footer
